import React from 'react';
import { Container, Typography, Box, Paper, Grid, Button, Modal, TextField } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CheckCircle, Cancel } from '@mui/icons-material'; // Import icons
import { useState } from 'react';


// Sample bearer token for demonstration
const bearerToken = "J4Ruz3nXwkvRct9aDCE5SCwNrFCJ0nrf";

// Define a simple theme with black and white colors
const theme = createTheme({
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
});

const sampleDataPayloadLeft = `
{
    "dataset": "movies",
    "data": {
        "id": "1",
        "movie_name": "Rocky Aur Rani Kii Prem Kahaani",
        "year": "2023",
        "genre": "Comedy, Drama, Family",
        "overview": "Rocky and Rani navigate family differences before marriage.",
        "director": "Karan Johar",
        "cast": "Ranveer Singh, Alia Bhatt, Dharmendra, Shabana Azmi"
    }
}
`

const sampleDataPayload = `
curl -X POST http://api.m-recommend.mobilytix-ai.com:3000/api/addDataRecord \
-H "Content-Type: application/json" \
-H "Authorization: Bearer J4Ruz3nXwkvRct9aDCE5SCwNrFCJ0nrf" \
-d '{
    "dataset": "movies",
    "data": {
        "id": "1",
        "movie_name": "Rocky Aur Rani Kii Prem Kahaani",
        "year": "2023",
        "genre": "Comedy, Drama, Family",
        "overview": "Rocky and Rani navigate family differences before marriage.",
        "director": "Karan Johar",
        "cast": "Ranveer Singh, Alia Bhatt, Dharmendra, Shabana Azmi"
    }
}'

`;

const sampleRecommendationCurlCall = `
curl -X POST http://api.m-recommend.mobilytix-ai.com:3000/api/getRecommendation \
-H "Content-Type: application/json" \
-H "Authorization: Bearer J4Ruz3nXwkvRct9aDCE5SCwNrFCJ0nrf" \
-d '{
    "dataset": "movies",
    "data": {
        "themes": "kidnap, hijack",
        "previously watched movies": "Neeraja"
    },
    "requestedCountOfMatches": 4
}'

`

const sampleRecommendationPayload = `{
    "dataset": "movies",
    "data": {
        "themes" : "kidnap, hijack",
        "previously watched movies": "Neeraja"
    },
    "requestedCountOfMatches": 3
}`;

const sampleRecommendationResult = `[
    {
        "id": "tt1132589",
        "movie_name": "Hijack",
        "year": "2008",
        "genre": "Action, Thriller",
        "description": "Mechanic's life changes due to a hijack."
    },
    {
        "id": "tt28946047",
        "movie_name": "Chalaak",
        "year": "2023",
        "genre": "Thriller",
        "description": "Kidnapped woman faces revelations and confronts her past."
    },
    {
        "id": "tt28494851",
        "movie_name": "Stolen",
        "year": "2023",
        "genre": "Drama, Thriller",
        "description": "Brothers help solve baby's kidnapping case."
    },
    {
        "id": "tt3848892",
        "movie_name": "Baby",
        "year": "2015",
        "genre": "Action, Crime, Thriller",
        "description": "Counter-intelligence unit fights against terrorist plot."
    },
]
`;

const App = () => {

  const [modalOpen, setModalOpen] = useState(true);
  const [password, setPassword] = useState('');

  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setModalOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'mrecommend') {
      setModalOpen(false);
    } else {
      alert('Incorrect password');
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box mt={5} mb={5}>
          {/* title */}



          <Grid container spacing={2} alignItems="center">
            {/* Merged row spanning across both columns */}
            <Grid item xs={12} md={6} textAlign="center">
              <Box sx={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0' }}>

                <Typography variant='h1'>
                  M-Recommend

                </Typography>
              </Box>
            </Grid>


            {/* Right column: Text content */}
            <Grid item xs={12} md={6}>
              <Box textAlign="center">

                <Typography variant="body" paragraph sx={{
                  color: '#000000',
                  fontSize: '1.25rem',
                  padding: '20px',
                  lineHeight: '1.5',
                }}>

                  Imagine building recommendation systems <strong>without the pain of complex data cleansing, building ML models, lengthy integrations, handling inconsistent data schema from multiple sources. </strong>.
                  <br /><br />
                  Build AI driven Recommendation Systems on the fly!

                  <br /><br />

                  Predict the <strong>next best _____________ </strong>(Reward / Movie / Offer / Store / Coupon... ) for your users, based on their past behavior, preferences, and context.
                  <br /><br />
                  Introducing <strong>M-RECOMMEND</strong>
                </Typography>
              </Box>
            </Grid>

            {/* Left column: Image placeholder */}
            <Grid item xs={12} md={12} textAlign="center">
              <Typography variant="h2" paragraph>
                <strong>How it Works!</strong>
              </Typography>
              <Box sx={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0' }}>
                {/* Placeholder for image */}

                <img src="/s3.png" alt="Cloud - SaaS Overview" style={{ width: '100%', borderRadius: '4px' }} />

              </Box>
            </Grid>
          </Grid>
        </Box>


        {/* Comparison Table */}
        {/* Comparison Table */}
        <Box mb={5}>
          <Typography variant="h4" gutterBottom style={{ color: '#000000', backgroundColor: '#ffffff', padding: '10px' }}>
            Comparison with Traditional Machine Learning Models
          </Typography>
          <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#ffffff', color: '#000000' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', color: '#000000', textAlign: 'center' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Feature</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>M-Recommend</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Traditional ML Models</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Accuracy</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Similar, works great for most of the use cases where recommendations are driven by user demographics, interests identified and past activity.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Similar, works better when recommendations are based on structured data, involving metric calculations, or seasonal trends.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Integration Effort</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> 1x
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> 100x
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Data Requirements</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Works with any JSON data, regardless of schema.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> Requires structured data with a strict schema.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Data Integration</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Simple plug-and-play with <code>/addDataRecord</code> API.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> Often requires complex ETL processes, data cleaning, and training.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Setup Time</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Instant setup and recommendations with APIs.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> Long setup time involving model training.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Customization</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Flexible recommendations based on provided context.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> High customization but complex implementation.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Maintenance</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Minimal maintenance with easy data updates.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> Ongoing maintenance and retraining needed.
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>Scalability</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <CheckCircle style={{ color: 'green', verticalAlign: 'middle' }} /> Easily handles millions of rows with dynamic scaling.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Cancel style={{ color: 'red', verticalAlign: 'middle' }} /> Scaling can be complex and costly.
                  </td>
                </tr>
              </tbody>
            </table>
          </Paper>
        </Box>


        {/* Load Your Data Section */}
        <Grid container spacing={4} mb={5}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#ffffff', color: '#000000' }}>
              <Typography variant="h4" gutterBottom style={{ color: '#000000' }}>
                Load Your Data
              </Typography>
              <Typography variant="body1" paragraph>
                Input your data effortlessly using the <code>/addDataRecord</code> API. For example, add movie data with:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f0f0f0', color: '#000000' }}>
                    <Typography variant="h6" gutterBottom>
                      Request Payload
                    </Typography>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleDataPayloadLeft}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f0f0f0', color: '#000000' }}>
                    <Typography variant="h6" gutterBottom>
                      Sample CURL call
                    </Typography>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleDataPayload}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Request Recommendations Section */}
        <Grid container spacing={4} mb={5}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#ffffff', color: '#000000' }}>
              <Typography variant="h4" gutterBottom style={{ color: '#000000' }}>
                Request Recommendations
              </Typography>
              <Typography variant="body1" paragraph>
                Retrieve personalized recommendations using the <code>/getRecommendation</code> API. Here’s how you can make a request and get results:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f0f0f0', color: '#000000' }}>
                    <Typography variant="h6" gutterBottom>
                      Request Payload
                    </Typography>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleRecommendationPayload}
                    </Typography>
                  </Paper>
                  <hr />
                  <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f0f0f0', color: '#000000' }}>
                    <Typography variant="h6" gutterBottom>
                      Sample CURL call
                    </Typography>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleRecommendationCurlCall}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f0f0f0', color: '#000000' }}>
                    <Typography variant="h6" gutterBottom>
                      Response
                    </Typography>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleRecommendationResult}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* Bearer Token Section */}
        <Box textAlign="center" mb={5}>
          <Typography variant="h5" gutterBottom>
            Try It Yourself
          </Typography>
          {/* Separator */}

          <Typography variant="body1" paragraph>
            Base URL: <code>http://api.m-recommend.mobilytix-ai.com:3000/api</code>
            <hr style={{ width: '50%', margin: 'auto', marginBottom: '20px' }} />
            Headers Required: <code>Content-Type: application/json</code>, <code>Authorization: Bearer {bearerToken}</code>
          </Typography>

          <Typography variant="body1" paragraph>
            Use the following bearer token to try out the APIs directly:
          </Typography>
          <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f0f0f0', color: '#000000', display: 'inline-block' }}>
            <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
              Bearer {bearerToken}
            </Typography>
          </Paper>
        </Box>

        {/* Give a table giving base url, API urls, bearer token */}
        <Box mb={5}>

          <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#ffffff', color: '#000000' }}>

            <table style={{ width: '100%', borderCollapse: 'collapse', color: '#000000' }}>
              <style>
                {`
      @media (max-width: 768px) {
        .hide-on-small {
          display: none;
        }
      }
    `}
              </style>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Method</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Endpoint</th>
                  <th className="hide-on-small" style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Description</th>
                  <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0' }}>Sample Payload</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>POST</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>/addDataRecord</td>
                  <td className="hide-on-small" style={{ border: '1px solid #ddd', padding: '8px' }}>Add a new data record to the dataset.
                    <br />  <br />
                    "id" is a mandatory key in the data JSON. This can be a unique identifier for the record on your system.
                    <br />  <br />
                    "dataset" is the name of the dataset you want to add the record to. If there is no such dataset, it is automatically created. You can add as many records to your dataset as you want. Datasets are unique to your tenant.
                    <br />  <br />
                    Records are upserted on the id. If a record with the same id already exists in the dataset, it is updated with the new data.
                    <br />  <br />
                    The keys in data are fluid. They need not be consistent across the dataset. The only mandatory key is "id". But it's recommended to keep it consistent. Minor inconsistencies, missing keys in records, won't significantly affect the recommendations.
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleDataPayloadLeft}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>POST</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>/getRecommendation</td>
                  <td className="hide-on-small" style={{ border: '1px solid #ddd', padding: '8px' }}>Get recommendations based on user preferences.

                    The schema of the data JSON is fluid. It need not exactly match the schema of the dataset. Provide all possible keys that you think are relevant for the recommendation engine to make better recommendations.

                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                      {sampleRecommendationPayload}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>


          </Paper>
        </Box>

        <Box textAlign="center" mt={5} mb={5}>
          <Button variant="contained" color="primary" startIcon={<CopyAll />}>
            {/* Link to email tanay.sai@comviva.com */}
            <a href="mailto:tanay.sai@comviva.com" style={{ textDecoration: 'none', color: '#ffffff' }}>
              Try M-Recommend Today
            </a>
          </Button>
        </Box>

        {/* New Section for Screenshot */}
        <Box mb={5}>
          <Typography variant="h4" gutterBottom style={{ color: '#000000', backgroundColor: '#ffffff', padding: '10px' }}>
            Cloud & SaaS
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <img src="/screenshot.png" alt="Cloud & SaaS Overview" style={{ width: '100%', borderRadius: '4px' }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#ffffff', color: '#000000' }}>

                <ul>
                  <li>Cloud Ready - Multi Tenant SaaS Product</li> <li>Deployable in-premise or on AWS</li> <li>Doesn't use any external OpenAI or other models. Uses only in-premise hosted models, that are also deployable on the cloud</li> <li>Provides UI for platform admins to manage tenants, and for tenant admins to manage their data sets, API keys, etc.</li>
                </ul>

              </Paper>
            </Grid>
          </Grid>
        </Box>



        {/* Modal */}
        {/* Modal for Password */}
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Enter Password
            </Typography>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} mt={2}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <Box mt={2}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

      </Container>
    </ThemeProvider >
  );
}

export default App;
